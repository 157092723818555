@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.common-input {
 height: 56px !important;
 width: 100% !important;
 background-color: transparent !important;
 border: 1px solid !important;
 border-color: rgb(120 113 108) !important;
 border-radius: 5px !important;
 color: white !important;
}
.common-input.active {
 background-color: rgb(4 47 46) !important;
 border-color: rgb(168 162 158) !important;
}
.common-input:hover{
 border-color: rgb(168 162 158) !important;
}
.common-input:focus{
 border-color: rgb(214 211 209) !important;
}

.common-input-button {
 height: 56px !important;
 background-color: transparent !important;
 border: 1px solid !important;
 border-color: rgb(120 113 108) !important;
 border-radius: 5px !important;
 color: white !important;
}
.common-input-button:hover{
 border-color: rgb(168 162 158) !important;
}
.common-input-button:focus{
 border-color: rgb(214 211 209) !important;
}
.phone-input {
color: #00f6ff;
background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  ) !important; 
width: 100% !important;
height: 2.8rem !important;
padding-top: 3px !important;
padding-bottom: 3px !important;
font-size: 0.875rem !important;
border: 1px solid #D1D5DB !important;
border-radius: 0.375rem !important;
outline: none !important;
ring-width: 1px !important;
ring-color: #9CA3AF !important;
}
.phone-input-dropdown {
 color: #000;
}
.my-actions { margin: 0 2em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }

.right-gap {
  margin-right: auto;
}

.fade {
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
  }
.may-like-heading {
  position: relative;
  overflow: hidden;
}

.may-like-heading:after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #d6d3d1;
}




